import '../index.css'
import { useTranslation } from 'react-i18next';
import Header from "../components/Header"
import axios from '../axios';
import common from '../common';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams} from 'react-router-dom';

export default function Wallet() {
  const { t, i18n } = useTranslation();
  const {lang} = useParams()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate(); 
  const [langQuery] = useState(searchParams.get('lang'));
  const [number] = useState(searchParams.get('number'));
  const [payment_token] = useState(searchParams.get('payment_token'));
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [stopLoading, setStopLoading] = useState(true)
  const [notify, setNotify] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    navigate(`/${langQuery}/payments/wallet?payment_token=${payment_token}&number=${number}&lang=${langQuery}`)
    i18n.changeLanguage(langQuery)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[langQuery, payment_token, number])

  const pay = () => {
    let data = {
      phone_number: number
    }
    setStopLoading(false)
    setNotify(false)

    axios.post(`payments/upg/pay/confirm/?payment_token=${payment_token}&lang=${lang}`, data, {})
      .then(() => {
        setPaymentSuccess(true)
        setStopLoading(true)
      })
      .catch((error) => {
        setStopLoading(true)
        setNotify(true)
        setMessage(common.notifyResponseError(error))
      })
  }

  return (
    <div>
      <div className={stopLoading ? '' : 'show-modal loading-window fixed inset-0 z-20'}>
        <div id="loading"></div>
      </div>
      {
        notify && 
        <div className="alert error mx-5">
          <p className="inner">{message}</p>
        </div>
      }
      <Header />
      <div className="m-auto px-5 py-10 mt-20" style={{maxWidth: '425px'}}>
        <p className="text-center leading-6 font-bold text-xl" style={{color: '#0A3266', letterSpacing: '0.3px'}}>{t('Shipment Payment')}</p>
        <div className="relative py-14" style={{height: 'calc(100svh - 184px)', minHeight: '270px'}}>
          { 
            paymentSuccess ?
            <div>
              <p className="text-lg text-center" style={{color: '#6B7280', letterSpacing: '0.3px'}}>{t('You will receive an SMS with instructions to pay for your shipment. After payment, please refresh the order page to update its status')}</p>
            </div> : 
            <div>
              <p style={{color: '#6B7280', letterSpacing: '0.3px'}}>{t('Your wallet mobile number')}</p>
              <input value={number} disabled type="text" className="rounded-2xl p-4 outline-none leading-4 w-full mt-3" style={{background: '#F3F3F3', color: '#4E4E4E', letterSpacing: '0.3px'}} />
              <div className="absolute bottom-0 w-full">
                <p className="text-xs leading-5 text-center mt-9" style={{color: '#6B7280', letterSpacing: '0.3px'}}>{t('100% secure payment processing for your shipment')}</p>
                <button onClick={pay} className="w-full rounded-md text-white cursor-pointer p-3 font-semibold mt-3" style={{background: '#1C5BFE', letterSpacing: '0.3px', height: 'fit-content'}}>{t('Pay Now')}</button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}